<template>
  <div
    class="my-5"
    :class="$vuetify.breakpoint.name === 'lg' ? 'pr-12 pl-9' : 'px-5'"
  >
    <!-- ==== FILTER SUMMARY ==== -->
    <!-- <v-row justify="start">
      <v-col cols="12" md="6">
        <v-text-field
          readonly
          v-if="store.length < 2"
          label="Pilih Outlet"
          color="#d31145"
          v-model="storeName"
          dense
          outlined
        >
        </v-text-field>
        <v-select
          outlined
          dense
          v-else
          v-model="filter.store_id"
          color="#d31145"
          attach
          :items="store"
          item-text="store_name"
          item-value="store_id"
          :hide-details="true"
          label="Pilih Outlet"
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="6">
        <date-picker
          v-model="value2"
          type="date"
          format="DD MMMM YYYY"
          range
          :clearable="false"
          :disabled-date="disabledAfterThisDay"
          :shortcuts="shortcuts"
          placeholder="Pilih Tanggal"
        >
          <template v-slot:header>
            <div class="title-date">Pilih Tanggal</div>
          </template>
        </date-picker>
      </v-col>
      <v-col cols="12" md="4">
        <VueCtkDateTimePicker
            color="#d31145"
            v-model="filter.filterdate"
            label="Pilih Tanggal"
            :range="true"
            :noClearButton="true"
            format="YYYY-MM-DD"
            formatted="LL"
            locale="id"
            :max-date="filter.dateNow"
            :custom-shortcuts="[
              { key: 'today', label: 'Hari ini', value: 'day' },
              { key: 'thisWeek', label: 'Minggu ini', value: 'isoWeek' },
              { key: 'lastWeek', label: 'Minggu lalu', value: '-isoWeek' },
              { key: 'last7Days', label: '7 hari terakhir', value: 7 },
              { key: 'last30Days', label: '30 hari terakhir', value: 30 },
              { key: 'thisMonth', label: 'Bulan ini', value: 'month' },
              { key: 'lastMonth', label: 'Bulan lalu', value: '-month' },
              { key: 'thisYear', label: 'Tahun ini', value: 'year' },
              { key: 'lastYear', label: 'Tahun lalu', value: '-year' }
            ]"
          />
      </v-col>
    </v-row> -->

    <p class="body-1 px-5 font-weight-bold text-second pt-0">SUMMARY FRANCHISE</p>
    <v-divider class="pb-5"></v-divider>

    <!-- ==== DATA SUMMARY PENJUALAN ==== -->
    <v-card outlined class="box-shadow border-radius pa-5 pb-1">
      <v-card-title class="body-1 px-5 text-second pt-0">
        JUMLAH MERCHANT DAN OUTLET
      </v-card-title>
      <v-row class="my-3 mb-6">
        <v-col
          cols="12"
          sm="6"
          lg="6"
          md="6"
          xs="12"
          v-for="(item, index) in process.run ? 6 : sales_summary"
          :key="index"
        >
          <v-skeleton-loader
            class="ma-auto"
            height="200"
            :loading="process.run"
            transition="scale-transition"
            :tile="false"
            type="image"
          >
            <v-card 
              :min-height="$vuetify.breakpoint.name === 'sm' ? 220 : ''"
              class="box-shadow border-radius py-5">
              <v-card-title class="subtitle-1 font-weight-bold">
                {{ item.title }}
                <v-spacer></v-spacer>
                <v-tooltip left v-if="item.help">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="#D31145"
                      v-bind="attrs"
                      v-on="on"
                      class="cursor-pointer"
                      size="20"
                      >mdi-help-circle</v-icon
                    >
                  </template>
                  <div v-html="item.help"></div>
                </v-tooltip>
              </v-card-title>
              <v-card-text class="subtitle-2">
                <p
                  class="text-second d-flex align-center"
                  style="font-size: 25px;"
                >
                  <span class="mr-3 mt-3">
                    {{ item.value }}
                  </span>
                  <v-spacer />
                  <span>
                    <v-icon size="40" :color="item.color">{{
                      item.icon
                    }}</v-icon>
                  </span>
                </p>
              </v-card-text>
            </v-card>
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-card>

    <!-- ==== DATA MERCHANT ==== -->
    <v-card outlined class="box-shadow border-radius pa-5 pb-1 my-5">
      <v-card-title class="body-1 px-5 text-second pt-0">
        DATA MERCHANT TERDAFTAR
      </v-card-title>
      <v-card-text>
        <div :class="$vuetify.breakpoint.name !== 'xs' ? 'my-5' : 'mb-5'" style="min-height: calc(100vh - 415px)">
          <v-skeleton-loader
            :loading="process.run"
            transition="scale-transition"
            :tile="false"
            type="table-tbody"
          >
            <base-table
              customHeight="calc(100vh - 350px)"
              :isExternalFilterButton="true"
              :isFilterOnHeader="true"
              v-show="displayBaseTable"
              ref="base-table"
              :filterHeader="filterHeader_merchantList"
              itemKey="id"
              :single-select="singleSelect"
              :headers="headers_merchantList"
              retrieveDataURL="franchise/merchant"
              v-on:onRetrieveDataEnd="responseDataEndMerchant"
            >

              <template v-slot:[`item.mobilephone`]="{item}">
                <a :href="`tel:+${item.mobilephone}`" v-if="item.mobilephone" class="text-decoration-underline">{{ item.mobilephone }}</a>
                <div v-else>-</div>
              </template>
            </base-table>
          </v-skeleton-loader>

          <!-- ==== COMPONENT DATA KOSONG ==== -->
          <Empty v-model="contentEmpty" v-show="emptyData" />
        </div>
      </v-card-text>
    </v-card>

    <!-- ==== DATA OUTLET ==== -->
    <v-card outlined class="box-shadow border-radius pa-5 pb-1 my-5">
      <v-card-title class="body-1 px-5 text-second pt-0">
        DATA OUTLET TERDAFTAR
      </v-card-title>
      <v-card-text>
        <div :class="$vuetify.breakpoint.name !== 'xs' ? 'my-5' : 'mb-5'" style="min-height: calc(100vh - 415px)">
          <v-skeleton-loader
            :loading="process.run"
            transition="scale-transition"
            :tile="false"
            type="table-tbody"
          >
            <base-table
              customHeight="calc(100vh - 350px)"
              :isExternalFilterButton="true"
              :isFilterOnHeader="true"
              v-show="displayBaseTable"
              ref="base-table"
              :filterHeader="filterHeader_storeList"
              itemKey="id"
              :single-select="singleSelect"
              :headers="headers_storeList"
              retrieveDataURL="franchise/store"
              v-on:onRetrieveDataEnd="responseDataEndStore"
            >
              <template v-slot:[`item.aksi_table`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      text
                      v-bind="attrs"
                      v-on="on"
                      :disabled="item.status == 'accept'"
                      min-width="30"
                      class="text-capitalize red--text pa-0 mr-1"
                      @click="actions(item, 'kick_out')">
                      <v-icon :size="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : ''">mdi-store-remove</v-icon>
                    </v-btn>
                  </template>
                  <span>Hapus dari Franchise</span>
                </v-tooltip>
              </template>

              <template v-slot:[`item.name`]="{item}">
                <v-list-item class="px-0">

                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2">
                      {{ item.store_name }} <strong class="red--text">{{ item.is_trial_bool == true ? '(Trial)' : '' }}</strong>
                    </v-list-item-title>
                    <v-list-item-subtitle v-html="item.category_name" class="caption"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <template v-slot:[`item.owner_name`]="{item}">
                <v-list-item class="pl-0">
                  <v-list-item-content>
                    <v-list-item-title v-html="item.owner_name" class="subtitle-2"></v-list-item-title>
                    <v-list-item-subtitle v-html="item.owner_email" class="caption"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <template v-slot:[`item.owner_mobilephone`]="{item}">
                <a :href="`tel:+${item.owner_mobilephone}`" class="text-decoration-underline">{{ item.owner_mobilephone }}</a>
              </template>

              <template v-slot:[`item.join_datetime`]="{item}">
                {{ item.join_datetime | datetime }}
              </template>
              
              <template v-slot:[`item.is_active_bool`]="{ item }">
                <div
                  :class="
                    item.is_active_bool
                      ? 'caption text-capitalize font-weight-bold text-center teal--text'
                      : 'caption text-capitalize font-weight-bold text-center red--text text--darken-2'
                  "
                >
                  {{ item.is_active_bool ? 'Aktif' : 'Tidak aktif' }}
                </div>
              </template>

              <template v-slot:filterTitle>
                Filter Data Pegawai
              </template>
            </base-table>
          </v-skeleton-loader>

          <!-- ==== COMPONENT DATA KOSONG ==== -->
          <Empty v-model="contentEmpty" v-show="emptyData" />
        </div>
      </v-card-text>
    </v-card>

    <!-- ==== DATA PERTUMBUHAN ==== -->
    <!-- <v-row>
      <v-col cols="12" md="12" class="mt-6">
        <v-skeleton-loader
          class="ma-auto"
          transition="scale-transition"
          :tile="true"
          type="card"
        >
          <v-card 
            outlined 
            class="box-shadow border-radius py-5">
            <v-card-title class="body-1 px-5 text-second pt-0 flex justify-space-between">
              <div>
                TOTAL PERTUMBUHAN OUTLET
              </div>
              <div style="width: 400px">
                <v-spacer></v-spacer>
                <VueCtkDateTimePicker
                  color="#d31145"
                  onlyDate
                  :range="true"
                  right
                  v-model="filter.filter_date"
                  label="Pilih Tanggal"
                  :noClearButton="true"
                  format="YYYY-MM-DD"
                  formatted="LL"
                  locale="id"
                  :max-date="new Date().toISOString().substr(0, 10)"
                  :custom-shortcuts="[
                    { key: 'today', label: 'Hari ini', value: 'day' },
                    { key: 'thisWeek', label: 'Minggu ini', value: 'isoWeek' },
                    { key: 'lastWeek', label: 'Minggu lalu', value: '-isoWeek' },
                    { key: 'last7Days', label: '7 hari terakhir', value: 7 },
                    { key: 'last30Days', label: '30 hari terakhir', value: 30 },
                    { key: 'thisMonth', label: 'Bulan ini', value: 'month' },
                    { key: 'lastMonth', label: 'Bulan lalu', value: '-month' },
                    { key: 'thisYear', label: 'Tahun ini', value: 'year' },
                    { key: 'lastYear', label: 'Tahun lalu', value: '-year' }
                  ]"
                />
              </div>
            </v-card-title>
            <v-card-subtitle class="caption px-5 text-third">
              <b>{{ filtered }}</b>
            </v-card-subtitle>
            <div class="mt-16 pa-3">
              <div v-if="Object.keys(register_bar).length > 0">
                <apexchart
                  height="300"
                  :options="register_bar.chartOptions"
                  :series="register_bar.series"
                >
                </apexchart>
              </div>
              <div
                class="title text-second d-flex align-center justify-center"
                v-if="register_bar.series == []"
                style="height: 200px;"
              >
                Data Belum Tersedia
              </div>
            </div>
          </v-card>
        </v-skeleton-loader>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import { get, post, put, destroy } from "../../service/Axios";
import moment from "moment";
import Empty from "@/components/Empty";
import Export from "@/components/Export";
import { TokenService } from "@/service/Storage.Service";
export default {
  components: {
    Empty,
    Export,
  },
  data() {
    return {
      isLoadAgains: false,
      filter: {
        store_id: "",
        filterdate: {},
        dateNow: new Date().toISOString().substr(0, 10),
      },
      value2: [],
      storeName: "",
      contentEmpty: "Data Belum Tersedia",
      dateNow: moment().format("YYYY-MM"),
      listyear: [],
      detail: {},
      sales_summary: [],
      process: {
        run: false,
      },

      /**
       * DATA COMPONENT BASETABLE STORE
       */
      displayBaseTable: false,
      emptyData: false,
      singleSelect: true,
      headers_storeList: [
        {
          text: "Tanggal Bergabung",
          sortable: true,
          width: "150",
          value: "join_datetime",
          align: "center",
        },
        {
          text: "Nama Outlet",
          sortable: true,
          width: "150",
          value: "name",
          align: "start",
        },
        {
          text: "Nama Pemilik",
          sortable: true,
          width: "150",
          value: "owner_name",
          align: "start",
        },
        {
          text: "No. Telepon",
          sortable: true,
          width: "150",
          value: "owner_mobilephone",
          align: "start",
        },
        {
          text: "Status",
          width: "50",
          align: "center",
          sortable: true,
          value: "is_active_bool",
        },
      ],
      headers_merchantList: [
        {
          text: "Nama Pemilik",
          sortable: true,
          width: "150",
          value: "name",
          align: "start",
        },
        {
          text: "No. Telepon",
          sortable: true,
          width: "150",
          value: "mobilephone",
          align: "start",
        },
        {
          text: "Email",
          width: "50",
          align: "start",
          sortable: true,
          value: "email",
        },
      ],

      filterHeader_merchantList: [
        {
          type: "opsi",
          column: "",
          valueDefault: "",
          label: "",
        },

        {
          type: "text",
          column: "name",
          valueDefault: "",
          label: "Berdasarkan Nama Outlet",
        },
        {
          type: "select",
          column: "user_is_active",
          valueDefault: "",
          label: "Pilih Status",
          items: ["waiting", "accept"],
        },
      ],
      filterHeader_storeList: [
        {
          type: "opsi",
          column: "",
          valueDefault: "",
          label: "",
        },

        {
          type: "text",
          column: "name",
          valueDefault: "",
          label: "Berdasarkan Nama Outlet",
        },
        {
          type: "select",
          column: "user_is_active",
          valueDefault: "",
          label: "Pilih Status",
          items: ["waiting", "accept"],
        },
      ],
      /**
       * END DATA COMPONENT BASETABLE
       */
    };
  },
  computed: {
    user() {
      return JSON.parse(TokenService.getUser());
    },
    filtered() {
      return this.value2
        ? moment(this.value2[0]).format("DD MMMM YYYY") +
            " - " +
            moment(this.value2[1]).format("DD MMMM YYYY")
        : "";
    },
    filterdateStart() {
      return this.value2[0]
        ? moment(this.value2[0]).format("YYYY-MM-DD") //HH:mm:ss
        : "";
    },
    filterdateEnd() {
      return this.value2[1]
        ? moment(this.value2[1]).format("YYYY-MM-DD") //HH:mm:ss
        : "";
    },
  },
  watch: {
    "filter.store_id": function(val) {
      this.initialize();
    },
    "filter.filterdate": function(val) {
      this.initialize();
    },
    value2: function(val) {
      this.initialize();
    },
  },
  mounted() {
    this.fetchSummary();
    
  },
  methods: {
    disabledAfterThisDay(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
    generateArrayOfYears() {
      var max = new Date().getFullYear();
      var min = 2020;

      for (var i = max; i >= min; i--) {
        this.listyear.push(i.toString());
      }
    },
    async fetchSummary() {
      await get(`franchise/dashboard/summary_merchant_outlet`)
        .then((response) => {
          let res = response.data;
          if (res.status === 200) {
            // this.sales_summary = res.results.data
            this.sales_summary = [
              {
                icon: "mdi-chart-line",
                title: res.results.data[0].label,
                value:
                  this.$options.filters.num_format(
                    res.results.data[0].value
                  ),
                help:
                  "Total Merchant yang bergabung di Franchise",
                color: "#f1c40f",
              },
              {
                icon: "mdi-chart-bell-curve-cumulative",
                title: res.results.data[1].label,
                value:
                  this.$options.filters.num_format(
                    res.results.data[1].value
                  ),
                help:
                  "Total Outlet yang terdaftar di Franchise",
                color: "#9b59b6",
              }
            ];
          }
        })
        .catch((error) => {
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            let errorHeader = error.response.headers;

            if (errorCode === 401) {
              this.$store.state.unauthorization = true;
              this.process.run = false;
              this.process.form = false;
            }
          } else {
            // this.$store.state.errorNetwork = true;
            this.process.run = false;
            this.process.form = false;
          }
        });
    },
    // BASE TABLE
    responseDataEndStore(res) {
      this.loading = false;
      this.process.run = false;
      if (res.data.length <= 0) {
        this.emptyData = true;
        this.displayBaseTable = false;
      } else {
        this.emptyData = false;
        this.displayBaseTable = true;
      }
      this.contentEmpty = "Data tidak ditemukan";
    },
    responseDataEndMerchant(res) {
      this.loading = false;
      this.process.run = false;
      if (res.data.length <= 0) {
        this.emptyData = true;
        this.displayBaseTable = false;
      } else {
        this.emptyData = false;
        this.displayBaseTable = true;
      }
      this.contentEmpty = "Data tidak ditemukan";
    },
    responseExport() {
      //send params from base table to base export
      this.$refs["base-export-excel"].params = this.$refs["base-table"].params;
      this.$refs["base-export-pdf"].params = this.$refs["base-table"].params;
    },
    // END BASE TABLE
    toFilter(item) {
      this.selected = item.id;
      this.filter.name = item.name;
    },
  },
};
</script>

<style>
.line {
  border-left: 6px solid #d31145 !important;
  transition: 0.3s;
}
</style>
